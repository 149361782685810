.btn2 {
  height: 48px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.47px;
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}

.btn-primary2 {
  background-color: #1874A7;
  height: 48px;
}

.btn-primary2:disabled {
  background-color: #AAA;
}

.btn-primary2:enabled:hover, .btn-primary2:focus, .btn-primary2:active {
  background-color: #0274A7D0;
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary2focus, .btn-primary2:active {
  background-color: #1874A7;
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-primary {
  color: #1874A7;
  height: 48px;
  border: 1px solid #1874A7;
}

.btn-outline-primary:enabled:hover, .tn-outline-primary:focus, .tn-outline-primary:active {
  background-color: #0274A7D0;
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-primary:disabled {
  color: #AAA;
  border-color: #AAA;
}



.btn-danger {
  background-color: #AF365E;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  background-color: #AF365ED0;
  outline: none !important;
  box-shadow: none !important;
}

.btn-danger:focus, .btn-danger:active {
  background-color: #AF365E;
  outline: none !important;
  box-shadow: none !important;
}

.text-button {
  border: 0;
  background-color: transparent;
  color: #0274A7;
  padding: 0;
  margin: 0;
  line-height: 32px;
  height: 32px;
  font-size: 32px;
  outline: none !important;
  box-shadow: none !important;
}

.text-button:hover {
  color: #0274A7D0;
}