.menu-bar {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 2px;
}

.menu-btn {
  width: 100px;
  margin-left: 2px;
  border-radius: 10px 10px 0 0;
}


.btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}