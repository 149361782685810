body, html {
  margin: 0;
  background-color: #3E4850; 
  min-height: 100%;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-width: 1024px; 
  margin: auto; 
  height: 100vh;
}

.inner {
  max-width: 1280px;
  margin: auto;
  min-height: 400px;
  background: rgb(95, 4, 4);
}

.outer {
  min-width: 1024px;
  height: 100%;
  background: #3E4850;;
  display: flex;
  flex-direction: column;
}