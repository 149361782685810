h1, h2, h3, h4, h5, p, body, span, input {
	font-family: Poppins, Arial, Helvetica, sans-serif;
	color: #000;
}

.poppins {
	font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

h1 {
	font-size: 48px;
	font-weight: 900;
	line-height: 72px;
}

h2 {
	font-size: 38px;
	font-weight: bold;
	letter-spacing: -0.75px;
	line-height: 58px;
}

h3 {
	font-size: 32px;
	font-weight: bold;
	letter-spacing: -0.65px;
	line-height: 48px;
}

h4 {
	font-size: 24px;
	font-weight: 600;
	line-height: 44px;
}

h5 {
	font-size: 24px;
	font-weight: normal;
}

.errortext {
	font-size: 11px;
	color: #A00;
}