.header-container {
  background-color: #e6ffe6;
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border-bottom: thin #000 solid;
}

.header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 240px;
}

.header-time {
  min-width: 240px;
}

.header-connnection {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #F00;
}

.header-connected {
  background-color: #0F0;
}

.blank-button, .blank-button:hover, .blank-button:focus {
  border: 0px;
  outline: none;
  background-color: transparent !important;
  color: #000;
}