.session-bar {
  background-color: rgb(174, 206, 235);
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.session-bar-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 4px;
}

.session-bar-middle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-width: 200px;
  border-left: 1px solid #333;
  padding: 4px;
}


.session-bar-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-width: 200px;
  padding: 4px;
}

.session-bar-buttons {
  display: flex;
  flex-direction: row;
  min-width: 300px;
  flex: 1;
  align-items: center;
}