.dealer-container {
  background-color: rgb(252, 170, 170);
  width: 100%;
  max-width: 1800px;
  min-width: 1024px;
  min-height: 600px;
  max-height: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2px;
}

.blurred {
  filter: blur(8px) grayscale(0.8) brightness(0.2);
}

.dealer-top {
  display: flex;
  flex-direction: row;
  height: 550px;
}

.dealer-bottom {
  display: flex;
  flex-direction: row;
  flex:1;
}

.inner-container {
  flex: 1;
  border: 1px solid #000;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inner-container-non-flex {
  border: 1px solid #000;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mono {
  font-family: 'Roboto Mono', monospace;
}

.animated {
    transition: all 0.1s ease;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.total-blur {
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color:#FFF;
  margin-top: 50px;
  margin-bottom: 50px;
}
