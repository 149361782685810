.admin-container {
  background-color: rgb(252, 170, 170);
  height: 100%;
  width: 100%;
  max-width: 1400px;
  min-width: 1024px;
  max-height: 1200px;

  margin: auto;
  display: flex;
  flex-direction: column;
}