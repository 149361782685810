

.linebox {
  flex: 1;
  margin: 1px;
  border: 1px solid #999;
  background-color: #000;
  color:#9AF;
  font-weight: 600;
}

.calling-out {
  background-color: #FF0 !important;
  color: #000 !important;
  transition: transform 0.8s;
  transform: rotateY(360deg);
  transform-style: preserve-3d;
}


.calling-in {
  background-color: #0FF !important;
  color: #000 !important;
}

.hover:hover {
  background-color: #333 !important;
  cursor: pointer;
}

.hoverin:hover {
  background-color: #0DD !important;
  cursor: pointer;
}
