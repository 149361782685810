.time-bar-value {
  display: flex;
  flex-direction: row;
  border: 1px solid #666;
  border-radius: 4px;
  overflow: hidden;
  width: 190px; 
  height: 32px;
}

.time-bar-value span {
  text-align: center;
  padding: 3px 0;
}

.time-bar-value span:first-of-type {
  border-right: 1px solid #666;
  background-color: rgb(145, 139, 119);
  color: #FFF;
  flex: 6;
}

.time-bar-value span:last-of-type {
  background-color: cornsilk;
  flex: 6;
  font-family: 'Roboto Mono', monospace;
}

.time-bar-value span.frozen {
  background-color: lightcoral;
}

.time-bar-value span.sliding {
  background-color: rgb(57, 255, 73);
}